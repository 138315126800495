.dialog-form {
  &__title {
    @apply flex
        items-center
        justify-between;
  }

  &__actions {
    @apply py-4
        px-6
        gap-2;
  }
}
