.complex-form {
  &__formwrapper {
    @apply
      flex;

    @screen xs {
      @apply
        flex-col;
    }

    @screen md {
      @apply
        flex-row;
    }

    > div {
      @apply flex-1;

      &:first-child {
        @screen xs {
          @apply mr-0;
        }

        @screen md {
          @apply mr-2;
        }
      }

      &:last-child {
        @screen xs {
          @apply ml-0;
        }

        @screen md {
          @apply ml-2;
        }
      }
    }
  }

  &__row {
    @apply
      flex
      items-center;

    &:first-child {
      @screen xs {
        @apply mb-4;
      }

      @screen md {
        @apply mb-0;
      }
    }

    > span {
      @apply
        text-base
        font-semibold
        mr-2;
    }
  }

  &__table {
    border-color: #dadada;
    @apply
      border
      rounded-sm;
  }

  &__thead {
    background-color: #fbfbfb;
    color: #323232;
    @apply
      font-semibold;
  }

  &__buttonadd {
    @apply my-4;
  }

  &__upload {
    @apply
      self-start;
  }

  &__buttonupload {
    @apply
      flex
      flex-col
      px-4
      py-6;
  }

  &__plusicon {
    @apply
      fill-current
      text-primary-500;
  }

  &__image {
    @apply mb-4;
  }
}
